/* eslint-disable no-param-reassign */
export function fetchOpsInitStateCreator(opNames) {
  return opNames.reduce((acc, opName) => ({
    ...acc,
    [opName]: {
      status: 'idle',
      error: null,
      meta: {},
    },
  }), {});
}

export function fetchPendingReducersCreator(fetchActionNames, opNames, domain) {
  return fetchActionNames.reduce((acc, fetchActionName, i) => {
    const opName = opNames[i];
    const pendingReducer = (state) => {
      state[domain][opName].status = 'loading';
    };
    return {
      ...acc,
      [fetchActionName.pending]: pendingReducer,
    };
  }, {});
}

export function fetchRejectedReducersCreator(fetchActionNames, opNames, domain) {
  return fetchActionNames.reduce((acc, fetchActionName, i) => {
    const opName = opNames[i];
    const rejectedReducer = (state, action) => {
      state[domain][opName].status = 'failed';
      state[domain][opName].error = action.error?.message || action.error;
      state[domain][opName].meta = {};
    };
    return {
      ...acc,
      [fetchActionName.rejected]: rejectedReducer,
    };
  }, {});
}

export function fetchResetReducersCreator(fetchActionNames, opNames, domain) {
  return fetchActionNames.reduce((acc, fetchActionName, i) => {
    const opName = opNames[i];
    const resetReducer = (state) => {
      state[domain][opName].status = 'idle';
      state[domain][opName].error = null;
      state[domain][opName].meta = {};
    };
    return {
      ...acc,
      [fetchActionName.reset]: resetReducer,
    };
  }, {});
}
