import {
  put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import {
  createJobPosting, deleteJobPosting, getJobPostings, updateJobPosting,
} from '../../api/jobPostings';
import parseAxiosError from '../../utils/helpers/parseAxiosError';
import {
  createJobPostingActionCreators,
  deleteJobPostingActionCreators,
  getJobPostingsActionCreators,
  updateJobPostingActionCreators,
} from '../actions/jobPostingsActionCreators';
import {
  createJobPostingConstants,
  deleteJobPostingConstants,
  getJobPostingsConstants,
  updateJobPostingConstants,
} from '../constants/jobPostingsConstants';

function* createJobPostingSaga({ payload }) {
  yield put(createJobPostingActionCreators.pending());
  try {
    const result = yield createJobPosting(payload);
    const {
      data: { data: jobPosting },
    } = result;
    yield put(createJobPostingActionCreators.fulfilled(jobPosting));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(createJobPostingActionCreators.rejected(errorParsed));
  }
}

function* getJobPostingsSaga({ payload: { pointer } }) {
  yield put(getJobPostingsActionCreators.pending());
  try {
    const result = yield getJobPostings(pointer);
    const {
      data: { data: jobPostings },
    } = result;
      // eslint-disable-next-line no-underscore-dangle
    const id = result.data?._meta?.pagination?.pointer?.id;
    yield put(getJobPostingsActionCreators.fulfilled(jobPostings, { pointer: id }));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(getJobPostingsActionCreators.rejected(errorParsed));
  }
}

function* updateJobPostingSaga({ payload: { id: postId, newValues } }) {
  yield put(updateJobPostingActionCreators.pending());
  try {
    const result = yield updateJobPosting(postId, newValues);
    const {
      data: {
        data: updatedJobPosting,
      },
    } = result;
    yield put(updateJobPostingActionCreators.fulfilled({ ...updatedJobPosting }));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(updateJobPostingActionCreators.rejected(errorParsed));
  }
}

function* deleteJobPostingSaga({ payload }) {
  try {
    const result = yield deleteJobPosting(payload);
    const { data: { data: { id } } } = result;
    yield put(deleteJobPostingActionCreators.fulfilled(id));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(deleteJobPostingActionCreators.rejected(errorParsed, { id: payload }));
  }
}

export default function* rootSaga() {
  yield takeEvery(createJobPostingConstants.name, createJobPostingSaga);
  yield takeLatest(getJobPostingsConstants.name, getJobPostingsSaga);
  yield takeEvery(updateJobPostingConstants.name, updateJobPostingSaga);
  yield takeEvery(deleteJobPostingConstants.name, deleteJobPostingSaga);
}
