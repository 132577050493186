import { ADMIN_ROLE_NAME } from '../utils/constants/url';
import config from '../config';
import Request from './request';

const { aws: { api: { invokeUrl } } } = config;

export const getJobPostings = (pointer, limit) => Request.get(`${invokeUrl}/job-postings?limit=${limit}&pointer=${pointer}`);

export const getJobPosting = (id) => Request.get(`${invokeUrl}/job-postings/${id}`);

export const createJobPosting = (body) => Request.post(`${invokeUrl}/${ADMIN_ROLE_NAME}/job-postings`, body, true);

export const updateJobPosting = (id, newValues) => Request.patch(`${invokeUrl}/${ADMIN_ROLE_NAME}/job-postings/${id}`, newValues, true);

export const deleteJobPosting = (id) => Request.delete(`${invokeUrl}/${ADMIN_ROLE_NAME}/job-postings/${id}`, true);
