import {
  getMessagesConstants, deleteMessageConstants, sendMessageConstants, updateMessageConstants,
} from '../constants/messagesConstants';

const { createFetchStandartFlowActionCreators } = require('../../utils/helpers/actionCreatorHelpers');

// eslint-disable-next-line import/prefer-default-export

export const getMessagesActionCreators = createFetchStandartFlowActionCreators(
  getMessagesConstants,
);

export const deleteMessageActionCreators = createFetchStandartFlowActionCreators(
  deleteMessageConstants,
);

export const sendMessageActionCreators = createFetchStandartFlowActionCreators(
  sendMessageConstants,
);
export const updateMessageActionCreators = createFetchStandartFlowActionCreators(
  updateMessageConstants,
);
