import axios from 'axios';
import currentSessionIdToken from '../utils/helpers/getCurrentSessionIdToken';

const Request = {
  async get(url, withAuth = false, header = {}) {
    const idToken = await currentSessionIdToken();

    if (withAuth) {
      return axios.get(url, {
        headers: {
          ...header,
          Authorization: `Bearer ${idToken}`,
        },
      });
    }

    return axios.get(url);
  },
  async post(url, body, withAuth = false, header = {}) {
    const idToken = await currentSessionIdToken();

    if (withAuth) {
      return axios.post(url, body, {
        headers: {
          ...header,
          Authorization: `Bearer ${idToken}`,
        },
      });
    }
    return axios.post(url, body);
  },
  async patch(url, body, withAuth = false, header = {}) {
    const idToken = await currentSessionIdToken();
    if (withAuth) {
      return axios.patch(url, body, {
        headers: {
          ...header,
          Authorization: `Bearer ${idToken}`,
        },
      });
    }
    return axios.patch(url, body);
  },
  async delete(url, withAuth = false, header = {}) {
    const idToken = await currentSessionIdToken();
    if (withAuth) {
      return axios.delete(url, {
        headers: {
          ...header,
          Authorization: `Bearer ${idToken}`,
        },
      });
    }
    return axios.delete(url);
  },
};

export default Request;
