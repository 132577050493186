/**
 * @param {Object} axiosError
 * @returns {Object}
 */
function parseAxiosError(axiosError) {
  if (!axiosError?.isAxiosError) {
    return axiosError;
  }
  const error = axiosError.response?.data?.error;
  if (!error) {
    return axiosError;
  }
  return error;
}

export default parseAxiosError;
