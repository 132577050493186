import {
  put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import {
  createBlogPost, deleteBlogPost, getBlogPosts, updateBlogPost,
} from '../../api/blogPosts';
import parseAxiosError from '../../utils/helpers/parseAxiosError';
import {
  createBlogPostActionCreators,
  deleteBlogPostActionCreators,
  getBlogPostsActionCreators,
  updateBlogPostActionCreators,
} from '../actions/blogPostsActionCreators';
import {
  createBlogPostConstants,
  deleteBlogPostConstants,
  getBlogPostsConstants,
  updateBlogPostConstants,
} from '../constants/blogPostsConstants';

function* createBlogPostSaga({ payload }) {
  yield put(createBlogPostActionCreators.pending());
  try {
    const result = yield createBlogPost(payload);
    const {
      data: {
        data: {
          blogPost: {
            // eslint-disable-next-line camelcase
            id, title, content, short_description, thumbnail, keywords,
          },
        },
      },
    } = result;

    yield put(createBlogPostActionCreators.fulfilled({
      id, title, content, short_description, thumbnail, keywords,
    }));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(createBlogPostActionCreators.rejected(errorParsed));
  }
}

function* getBlogPostsSaga({ payload: { pointer } }) {
  yield put(getBlogPostsActionCreators.pending());
  try {
    const result = yield getBlogPosts(pointer);
    const {
      data: { data: { blogPosts } },
    } = result;
    // eslint-disable-next-line no-underscore-dangle
    const id = result.data?._meta?.pagination?.pointer?.id;
    yield put(getBlogPostsActionCreators.fulfilled(blogPosts, { pointer: id }));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(getBlogPostsActionCreators.rejected(errorParsed));
  }
}

function* updateBlogPostSaga({ payload: { id: postId, newValues } }) {
  yield put(updateBlogPostActionCreators.pending());
  try {
    const result = yield updateBlogPost(postId, newValues);
    const {
      data: {
        data: {
          updatedBlogPost: {
            // eslint-disable-next-line camelcase
            id, title, content, short_description, thumbnail, keywords,
          },
        },
      },
    } = result;

    yield put(updateBlogPostActionCreators.fulfilled({
      oldId: postId, id, title, content, short_description, thumbnail, keywords,
    }));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(updateBlogPostActionCreators.rejected(errorParsed));
  }
}

function* deleteBlogPostSaga({ payload }) {
  try {
    const result = yield deleteBlogPost(payload);
    const { data: { data: { deletedBlogPost: { id } } } } = result;
    yield put(deleteBlogPostActionCreators.fulfilled(id));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(deleteBlogPostActionCreators.rejected(errorParsed, { id: payload }));
  }
}

export default function* rootSaga() {
  yield takeEvery(createBlogPostConstants.name, createBlogPostSaga);
  yield takeLatest(getBlogPostsConstants.name, getBlogPostsSaga);
  yield takeEvery(updateBlogPostConstants.name, updateBlogPostSaga);
  yield takeEvery(deleteBlogPostConstants.name, deleteBlogPostSaga);
}
