// eslint-disable-next-line import/prefer-default-export
export const createActionNameForProcessingFetch = (actionDefaultName, domainName) => {
  let actionName = actionDefaultName;

  if (domainName) {
    actionName = `${domainName}/${actionName}`;
  }

  return {
    name: actionName,
    pending: `${actionName}/pending`,
    fulfilled: `${actionName}/fulfilled`,
    rejected: `${actionName}/rejected`,
    reset: `${actionName}/reset`,
  };
};

export const createFetchStandartFlowActionCreators = (actionNames) => ({
  name: (payload = {}) => ({ type: actionNames.name, payload }),
  pending: () => ({ type: actionNames.pending }),
  reset: () => ({ type: actionNames.reset }),
  fulfilled: (payload = {}, meta) => ({
    type: actionNames.fulfilled,
    payload,
    meta,
  }),
  rejected: (error, meta) => ({
    type: actionNames.rejected,
    error,
    meta,
  }),
});
