import {
  createJobPostingConstants,
  deleteJobPostingConstants,
  getJobPostingsConstants,
  updateJobPostingConstants,
} from '../constants/jobPostingsConstants';

const { createFetchStandartFlowActionCreators } = require('../../utils/helpers/actionCreatorHelpers');

// eslint-disable-next-line import/prefer-default-export
export const createJobPostingActionCreators = createFetchStandartFlowActionCreators(
  createJobPostingConstants,
);

export const getJobPostingsActionCreators = createFetchStandartFlowActionCreators(
  getJobPostingsConstants,
);

export const updateJobPostingActionCreators = createFetchStandartFlowActionCreators(
  updateJobPostingConstants,
);

export const deleteJobPostingActionCreators = createFetchStandartFlowActionCreators(
  deleteJobPostingConstants,
);
