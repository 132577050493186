import {
  put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import {
  getMessages, deleteMessage, sendMessage, updateMessage,
} from '../../api/messages';
import parseAxiosError from '../../utils/helpers/parseAxiosError';
import {
  getMessagesActionCreators,
  deleteMessageActionCreators, sendMessageActionCreators, updateMessageActionCreators,
} from '../actions/messagesActionCreators';
import {
  getMessagesConstants,
  deleteMessageConstants, sendMessageConstants, updateMessageConstants,
} from '../constants/messagesConstants';

function* getMessagesSaga({ payload: { pointer } }) {
  yield put(getMessagesActionCreators.pending());
  try {
    const result = yield getMessages(pointer);
    const {
      data: { data: messages },
    } = result;
    // eslint-disable-next-line no-underscore-dangle
    const id = result.data?._meta?.pagination?.pointer?.id;
    yield put(getMessagesActionCreators.fulfilled(messages, { pointer: id }));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(getMessagesActionCreators.rejected(errorParsed));
  }
}

function* deleteMessageSaga({ payload }) {
  try {
    const result = yield deleteMessage(payload);
    const { data: { data: { id } } } = result;
    yield put(deleteMessageActionCreators.fulfilled(id));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(deleteMessageActionCreators.rejected(errorParsed, { id: payload }));
  }
}

function* updateMessageSaga({ payload: { id: postId, isSent } }) {
  yield put(updateMessageActionCreators.pending());
  try {
    const result = yield updateMessage(postId, isSent);
    const {
      data: {
        data: updatedMessage,
      },
    } = result;
    yield put(updateMessageActionCreators.fulfilled({ ...updatedMessage }));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(updateMessageActionCreators.rejected(errorParsed));
  }
}

function* sendMessageSaga({ payload }) {
  yield put(sendMessageActionCreators.pending());
  try {
    const result = yield sendMessage(payload);
    yield put(sendMessageActionCreators.fulfilled());
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(sendMessageActionCreators.rejected(errorParsed));
  }
}

export default function* rootSaga() {
  yield takeLatest(getMessagesConstants.name, getMessagesSaga);
  yield takeEvery(deleteMessageConstants.name, deleteMessageSaga);
  yield takeEvery(sendMessageConstants.name, sendMessageSaga);
  yield takeEvery(updateMessageConstants.name, updateMessageSaga);
}
