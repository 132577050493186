const {
  REACT_APP_AWS_API_INVOKE_URL,
  REACT_APP_AWS_COGNITO_REGION,
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
  REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
  REACT_APP_AWS_S3_URL,
  REACT_APP_AWS_IMG_HANDLER_URL,
} = process.env;

const config = {
  aws: {
    api: {
      invokeUrl: REACT_APP_AWS_API_INVOKE_URL,
    },
    cognito: {
      region: REACT_APP_AWS_COGNITO_REGION,
      userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
      appClientId: REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
    },
    s3: {
      url: REACT_APP_AWS_S3_URL,
    },
    img_handler: {
      url: REACT_APP_AWS_IMG_HANDLER_URL,
    },
  },
};

export default config;
