import {
  put, takeLatest,
} from 'redux-saga/effects';
import {
  getJobApplications,
} from '../../api/jobApplications';
import parseAxiosError from '../../utils/helpers/parseAxiosError';
import {
  getJobApplicationsActionCreators,
} from '../actions/jobApplicationsActionCreators';
import {
  getJobApplicationsConstants,
} from '../constants/jobApplicationsConstants';

function* getJobApplicationSaga({ payload: { pointer } }) {
  yield put(getJobApplicationsActionCreators.pending());
  try {
    const result = yield getJobApplications(pointer);
    const {
      data: { data: jobApplications },
    } = result;
    // eslint-disable-next-line no-underscore-dangle
    const id = result.data?._meta?.pagination?.pointer?.id;
    yield put(getJobApplicationsActionCreators.fulfilled(jobApplications, { pointer: id }));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(getJobApplicationsActionCreators.rejected(errorParsed));
  }
}

export default function* rootSaga() {
  yield takeLatest(getJobApplicationsConstants.name, getJobApplicationSaga);
}
