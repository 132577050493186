import { ADMIN_ROLE_NAME } from '../utils/constants/url';
// aws
import config from '../config';
import Request from './request';

const { aws: { api: { invokeUrl } } } = config;

// api

export const getBlogPosts = (pointer, limit) => Request.get(`${invokeUrl}/blog-posts?limit=${limit}&pointer=${pointer}`);

export const getBlogPost = (id) => Request.get(`${invokeUrl}/blog-posts/${id}`);

export const createBlogPost = (body) => Request.post(`${invokeUrl}/${ADMIN_ROLE_NAME}/blog-posts`, body, true);

export const updateBlogPost = (id, newValues) => Request.post(`${invokeUrl}/${ADMIN_ROLE_NAME}/blog-posts/${id}`, newValues, true);

export const deleteBlogPost = (id) => Request.delete(`${invokeUrl}/${ADMIN_ROLE_NAME}/blog-posts/${id}`, true);
