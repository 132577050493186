import axios from 'axios';
import { ADMIN_ROLE_NAME } from '../utils/constants/url';
// aws
import config from '../config';
// utils
import currentSessionIdToken from '../utils/helpers/getCurrentSessionIdToken';

const { aws: { api: { invokeUrl } } } = config;

// upload

export const getImageUploadSignedUrl = async (ext) => {
  const idToken = await currentSessionIdToken();
  return axios.get(`${invokeUrl}/${ADMIN_ROLE_NAME}/assets/get-image-upload-url${ext && `?ext=${ext}`}`, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
};

export const uploadImageToSignedUrl = async (url, image) => axios.put(url, image, {
  headers: {
    'Content-Type': 'binary/octet-stream',
  },
});

// fetch

export const getImages = async (pointer) => {
  const idToken = await currentSessionIdToken();
  let url = `${invokeUrl}/${ADMIN_ROLE_NAME}/assets/get-images`;
  if (pointer) {
    url += `?pointer=${encodeURIComponent(pointer)}`;
  }

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
};

export const deleteImageByKey = async (key) => {
  const idToken = await currentSessionIdToken();
  return axios.delete(`${invokeUrl}/${ADMIN_ROLE_NAME}/assets/deleteImage/${encodeURIComponent(key)}`, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
};
