import {
  fetchOpsInitStateCreator,
  fetchPendingReducersCreator,
  fetchRejectedReducersCreator,
  fetchResetReducersCreator,
} from '../../utils/helpers/fetchReducerHelpers';
import {
  createBlogPostConstants,
  deleteBlogPostConstants,
  getBlogPostsConstants,
  updateBlogPostConstants,
} from '../constants/blogPostsConstants';
import { BLOG_POSTS_DOMAIN_NAME } from '../constants/constants';

/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

const fetchOpNames = {
  pending: [
    'createBlogPost',
    'getBlogPosts',
    'updateBlogPost',
  ],
  rejected: [
    'createBlogPost',
    'getBlogPosts',
    'updateBlogPost',
  ],
  reset: [
    'createBlogPost',
    'getBlogPosts',
    'updateBlogPost',
    'deleteBlogPost',
  ],
};

const fetchActionNames = {
  pending: [
    createBlogPostConstants,
    getBlogPostsConstants,
    updateBlogPostConstants,
  ],
  rejected: [
    createBlogPostConstants,
    getBlogPostsConstants,
    updateBlogPostConstants,
  ],
  reset: [
    createBlogPostConstants,
    getBlogPostsConstants,
    updateBlogPostConstants,
    deleteBlogPostConstants,
  ],
};

const fetchOps = fetchOpsInitStateCreator([
  'createBlogPost',
  'getBlogPosts',
  'updateBlogPost',
  'deleteBlogPost',
]);

const fetchPendingReducers = fetchPendingReducersCreator(fetchActionNames.pending, fetchOpNames.pending, 'fetchOps');

const fetchRejectedReducers = fetchRejectedReducersCreator(fetchActionNames.rejected, fetchOpNames.rejected, 'fetchOps');

const fetchResetReducers = fetchResetReducersCreator(fetchActionNames.reset, fetchOpNames.reset, 'fetchOps');

const initialState = {
  posts: [],
  fetchOps,
};

const blogPostsSlice = createSlice({
  name: BLOG_POSTS_DOMAIN_NAME,
  initialState,
  extraReducers: {
    ...fetchPendingReducers,
    ...fetchRejectedReducers,
    ...fetchResetReducers,
    [createBlogPostConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.createBlogPost.status = 'succeeded';
      state.fetchOps.createBlogPost.error = null;
      state.posts.unshift(payload);
    },
    [getBlogPostsConstants.fulfilled]: (state, { payload, meta }) => {
      state.fetchOps.getBlogPosts.status = 'succeeded';
      state.fetchOps.getBlogPosts.error = null;
      state.fetchOps.getBlogPosts.meta.pointer = meta.pointer;
      state.posts = state.posts.concat(payload);
    },
    [getBlogPostsConstants.reset]: (state) => {
      state.fetchOps.getBlogPosts.status = 'idle';
      state.fetchOps.getBlogPosts.error = null;
    },
    [updateBlogPostConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.updateBlogPost.status = 'succeeded';
      state.fetchOps.updateBlogPost.error = null;
      const {
        // eslint-disable-next-line camelcase
        oldId, id, title, content, short_description, thumbnail, keywords,
      } = payload;
      state.posts = state.posts.filter((post) => post.id !== oldId);
      state.posts.unshift({
        id, title, content, short_description, thumbnail, keywords,
      });
    },
    [deleteBlogPostConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.deleteBlogPost.status = 'succeeded';
      state.fetchOps.deleteBlogPost.error = null;
      state.fetchOps.deleteBlogPost.meta.id = payload;
      state.posts = state.posts.filter((post) => post.id !== payload);
    },
    [deleteBlogPostConstants.rejected]: (state, action) => {
      state.fetchOps.deleteBlogPost.status = 'failed';
      state.fetchOps.deleteBlogPost.error = action.error?.message || action.error;
      state.fetchOps.deleteBlogPost.meta.id = action.meta.id;
    },
  },
});

export const { postsLoaded, postUpdated, postDeleted } = blogPostsSlice.actions;

export default blogPostsSlice.reducer;
