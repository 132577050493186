// aws
import config from '../config';
import { ADMIN_ROLE_NAME } from '../utils/constants/url';

// utils
import Request from './request';

const { aws: { api: { invokeUrl } } } = config;

export const getMessages = (pointer, limit) => Request.get(`${invokeUrl}/${ADMIN_ROLE_NAME}/messages?limit=${limit}&pointer=${pointer}`, true);

export const deleteMessage = (id) => Request.delete(`${invokeUrl}/${ADMIN_ROLE_NAME}/messages/${id}`, true);

export const sendMessage = (body) => Request.post(`${invokeUrl}/${ADMIN_ROLE_NAME}/messages/`, body, true);

export const updateMessage = (id, newValues) => Request.patch(`${invokeUrl}/${ADMIN_ROLE_NAME}/messages/${id}`, newValues, true);
