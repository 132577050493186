// redux-saga
import {
  takeLeading, put, takeLatest, takeEvery,
} from 'redux-saga/effects';
// api
import {
  getImageUploadSignedUrl, uploadImageToSignedUrl, getImages, deleteImageByKey,
} from '../../api/assets';

// errors
import parseAxiosError from '../../utils/helpers/parseAxiosError';
import { createImageActionCreators, deleteImageActionCreators, getImagesActionCreators } from '../actions/imagesActionCreators';
import { createImageConstants, deleteImageConstants, getImagesConstants } from '../constants/imagesConstants';

function* createImageSaga({ payload: { image, ext } }) {
  yield put(createImageActionCreators.pending());
  try {
    const { data: { data: { url: signedUrl, key } } } = yield getImageUploadSignedUrl(ext);
    yield uploadImageToSignedUrl(signedUrl, image);
    yield put(createImageActionCreators.fulfilled(key));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(createImageActionCreators.rejected(errorParsed));
  }
}

function* getImagesSaga({ payload }) {
  yield put(getImagesActionCreators.pending());
  try {
    const result = yield getImages(payload.pointer);
    const { data: { data: { keys }, _meta: { pagination: { pointer, hasMore } } } } = result;
    yield put(getImagesActionCreators.fulfilled(keys, { pointer, hasMore }));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(getImagesActionCreators.rejected(errorParsed));
  }
}

function* deleteImageSaga({ payload: key }) {
  try {
    yield deleteImageByKey(key);
    yield put(deleteImageActionCreators.fulfilled(key));
  } catch (e) {
    const errorParsed = parseAxiosError(e);
    yield put(deleteImageActionCreators.rejected(errorParsed));
  }
}

// eslint-disable-next-line import/prefer-default-export
export default function* rootSaga() {
  yield takeLeading(createImageConstants.name, createImageSaga);
  yield takeLatest(getImagesConstants.name, getImagesSaga);
  yield takeEvery(deleteImageConstants.name, deleteImageSaga);
}
