import {
  getJobApplicationsConstants,
} from '../constants/jobApplicationsConstants';

const { createFetchStandartFlowActionCreators } = require('../../utils/helpers/actionCreatorHelpers');

// eslint-disable-next-line import/prefer-default-export

// eslint-disable-next-line import/prefer-default-export
export const getJobApplicationsActionCreators = createFetchStandartFlowActionCreators(
  getJobApplicationsConstants,
);
