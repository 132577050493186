// redux
import { createStore, applyMiddleware } from 'redux';
// dev-tools
import { composeWithDevTools } from 'redux-devtools-extension';
// redux-saga
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/rootSaga';
// reducer
import rootReducer from '../reducers';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export default store;
