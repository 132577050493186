import { createActionNameForProcessingFetch } from '../../utils/helpers/actionCreatorHelpers';
import { BLOG_POSTS_DOMAIN_NAME } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const createBlogPostConstants = createActionNameForProcessingFetch('createBlogPost', BLOG_POSTS_DOMAIN_NAME);

export const getBlogPostsConstants = createActionNameForProcessingFetch('getBlogPosts', BLOG_POSTS_DOMAIN_NAME);

export const updateBlogPostConstants = createActionNameForProcessingFetch('updateBlogPost', BLOG_POSTS_DOMAIN_NAME);

export const deleteBlogPostConstants = createActionNameForProcessingFetch('deleteBlogPost', BLOG_POSTS_DOMAIN_NAME);
