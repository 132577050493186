import { createActionNameForProcessingFetch } from '../../utils/helpers/actionCreatorHelpers';
import { MESSAGES_DOMAIN_NAME } from './constants';

// eslint-disable-next-line import/prefer-default-export

export const getMessagesConstants = createActionNameForProcessingFetch('getMessages', MESSAGES_DOMAIN_NAME);

export const deleteMessageConstants = createActionNameForProcessingFetch('deleteMessage', MESSAGES_DOMAIN_NAME);

export const sendMessageConstants = createActionNameForProcessingFetch('sendMessage', MESSAGES_DOMAIN_NAME);

export const updateMessageConstants = createActionNameForProcessingFetch('updateMessage', MESSAGES_DOMAIN_NAME);
