import {
  fetchOpsInitStateCreator,
  fetchPendingReducersCreator,
  fetchRejectedReducersCreator,
  fetchResetReducersCreator,
} from '../../utils/helpers/fetchReducerHelpers';
import {
  createImageConstants, deleteImageConstants, getImagesConstants,
} from '../constants/imagesConstants';

import { IMAGES_DOMAIN_NAME } from '../constants/constants';

/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

const fetchOpNames = {
  pending: [
    'createImage',
    'getImages',
    'deleteImage',
  ],
  rejected: [
    'createImage',
    'getImages',
    'deleteImage',
  ],
  reset: [
    'createImage',
    'getImages',
    'deleteImage',
  ],
};

const fetchActionNames = {
  pending: [
    createImageConstants,
    getImagesConstants,
    deleteImageConstants,
  ],
  rejected: [
    createImageConstants,
    getImagesConstants,
    deleteImageConstants,
  ],
  reset: [
    createImageConstants,
    getImagesConstants,
    deleteImageConstants,
  ],
};

const fetchOps = fetchOpsInitStateCreator([
  'createImage',
  'getImages',
  'deleteImage',
]);

const fetchPendingReducers = fetchPendingReducersCreator(fetchActionNames.pending, fetchOpNames.pending, 'fetchOps');

const fetchRejectedReducers = fetchRejectedReducersCreator(fetchActionNames.rejected, fetchOpNames.rejected, 'fetchOps');

const fetchResetReducers = fetchResetReducersCreator(fetchActionNames.reset, fetchOpNames.reset, 'fetchOps');

const initialState = {
  keys: [],
  fetchOps,
};

const imagesSlice = createSlice({
  name: IMAGES_DOMAIN_NAME,
  initialState,
  extraReducers: {
    ...fetchPendingReducers,
    ...fetchRejectedReducers,
    ...fetchResetReducers,
    [createImageConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.createImage.status = 'succeeded';
      state.fetchOps.createImage.error = null;
      state.keys.unshift(payload);
    },
    [getImagesConstants.fulfilled]: (state, { payload, meta }) => {
      state.fetchOps.getImages.status = 'succeeded';
      state.fetchOps.getImages.error = null;
      state.fetchOps.getImages.meta.pointer = meta.pointer;
      state.fetchOps.getImages.meta.hasMore = meta.hasMore;
      state.keys = state.keys.concat(payload);
    },
    [getImagesConstants.reset]: (state) => {
      state.fetchOps.getImages.status = 'idle';
      state.fetchOps.getImages.error = null;
    },
    [deleteImageConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.deleteImage.status = 'succeeded';
      state.fetchOps.deleteImage.error = null;
      state.keys = state.keys.filter((key) => key !== payload);
    },
  },
});

export default imagesSlice.reducer;
