import {
  fetchOpsInitStateCreator,
  fetchPendingReducersCreator,
  fetchRejectedReducersCreator,
  fetchResetReducersCreator,
} from '../../utils/helpers/fetchReducerHelpers';
import {
  getMessagesConstants,
  deleteMessageConstants,
  sendMessageConstants,
  updateMessageConstants,
} from '../constants/messagesConstants';
import { MESSAGES_DOMAIN_NAME } from '../constants/constants';

/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

const fetchOpNames = {
  pending: [
    'getMessages',
    'sendMessage',
    'updateMessage',
  ],
  rejected: [
    'getMessages',
    'sendMessage',
    'updateMessage',
  ],
  reset: [
    'getMessages',
    'sendMessage',
    'updateMessage',
    'deleteMessage',
  ],
};

const fetchActionNames = {
  pending: [
    getMessagesConstants,
    sendMessageConstants,
    updateMessageConstants,
  ],
  rejected: [
    getMessagesConstants,
    sendMessageConstants,
    updateMessageConstants,
  ],
  reset: [
    getMessagesConstants,
    sendMessageConstants,
    updateMessageConstants,
    deleteMessageConstants,
  ],
};

const fetchOps = fetchOpsInitStateCreator([
  'getMessages',
  'sendMessage',
  'updateMessage',
  'deleteMessage',
]);

const fetchPendingReducers = fetchPendingReducersCreator(fetchActionNames.pending, fetchOpNames.pending, 'fetchOps');

const fetchRejectedReducers = fetchRejectedReducersCreator(fetchActionNames.rejected, fetchOpNames.rejected, 'fetchOps');

const fetchResetReducers = fetchResetReducersCreator(fetchActionNames.reset, fetchOpNames.reset, 'fetchOps');

const initialState = {
  posts: {},
  fetchOps,
};

const messagesSlice = createSlice({
  name: MESSAGES_DOMAIN_NAME,
  initialState,
  extraReducers: {
    ...fetchPendingReducers,
    ...fetchRejectedReducers,
    ...fetchResetReducers,
    [getMessagesConstants.fulfilled]: (state, { payload, meta }) => {
      state.fetchOps.getMessages.status = 'succeeded';
      state.fetchOps.getMessages.error = null;
      state.fetchOps.getMessages.meta.pointer = meta.pointer;
      const postsMapped = {};
      payload.forEach((post) => {
        postsMapped[post.id] = post;
      });
      state.posts = { ...state.posts, ...postsMapped };
    },
    [getMessagesConstants.reset]: (state) => {
      state.fetchOps.getMessages.status = 'idle';
      state.fetchOps.getMessages.error = null;
    },
    [sendMessageConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.sendMessage.status = 'succeeded';
      state.fetchOps.sendMessage.error = null;
      state.fetchOps.sendMessage.meta.id = payload;
    },
    [updateMessageConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.updateMessage.status = 'succeeded';
      state.fetchOps.updateMessage.error = null;
      state.posts[payload.id] = { ...state.posts[payload.id], ...payload };
    },
    [deleteMessageConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.deleteMessage.status = 'succeeded';
      state.fetchOps.deleteMessage.error = null;
      state.fetchOps.deleteMessage.meta.id = payload;
      state.posts[payload] = null;
    },
    [deleteMessageConstants.rejected]: (state, action) => {
      state.fetchOps.deleteMessage.status = 'failed';
      state.fetchOps.deleteMessage.error = action.error?.message || action.error;
      state.fetchOps.deleteMessage.meta.id = action.meta.id;
    },
  },
});

export const { postsLoaded, postUpdated, postDeleted } = messagesSlice.actions;

export default messagesSlice.reducer;
