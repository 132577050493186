import { combineReducers } from 'redux';
import coreUiReducer from './coreUi';

import blogPostsReducer from './blogPostsSlice';
import jobPostingsReducer from './jobPostingsSlice';
import imagesReducer from './imagesSlice';
import jobApplicationsReducer from './jobApplicationsSlice';
import messagesReducer from './messagesSlice';

const rootReducer = combineReducers({
  coreUi: coreUiReducer,
  blogPosts: blogPostsReducer,
  jobPostings: jobPostingsReducer,
  images: imagesReducer,
  jobApplications: jobApplicationsReducer,
  messages: messagesReducer,
});

export default rootReducer;
