import {
  createImageConstants,
  deleteImageConstants,
  getImagesConstants,
} from '../constants/imagesConstants';

const { createFetchStandartFlowActionCreators } = require('../../utils/helpers/actionCreatorHelpers');

// eslint-disable-next-line import/prefer-default-export
export const createImageActionCreators = createFetchStandartFlowActionCreators(
  createImageConstants,
);

export const getImagesActionCreators = createFetchStandartFlowActionCreators(
  getImagesConstants,
);

export const deleteImageActionCreators = createFetchStandartFlowActionCreators(
  deleteImageConstants,
);
