import {
  fetchOpsInitStateCreator,
  fetchPendingReducersCreator,
  fetchRejectedReducersCreator,
  fetchResetReducersCreator,
} from '../../utils/helpers/fetchReducerHelpers';
import {
  getJobApplicationsConstants,
} from '../constants/jobApplicationsConstants';

import { JOB_APPLICATIONS_DOMAIN_NAME } from '../constants/constants';

/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

const fetchOpNames = {
  pending: [
    'getJobApplications',
  ],
  rejected: [
    'getJobApplications',
  ],
  reset: [
    'getJobApplications',
  ],
};

const fetchActionNames = {
  pending: [
    getJobApplicationsConstants,
  ],
  rejected: [
    getJobApplicationsConstants,
  ],
  reset: [
    getJobApplicationsConstants,
  ],
};

const fetchOps = fetchOpsInitStateCreator([
  'getJobApplications',
]);

const fetchPendingReducers = fetchPendingReducersCreator(fetchActionNames.pending, fetchOpNames.pending, 'fetchOps');

const fetchRejectedReducers = fetchRejectedReducersCreator(fetchActionNames.rejected, fetchOpNames.rejected, 'fetchOps');

const fetchResetReducers = fetchResetReducersCreator(fetchActionNames.reset, fetchOpNames.reset, 'fetchOps');

const initialState = {
  posts: [],
  fetchOps,
  status: 'loading',
};

const jobApplicationsSlice = createSlice({
  name: JOB_APPLICATIONS_DOMAIN_NAME,
  initialState,
  extraReducers: {
    ...fetchPendingReducers,
    ...fetchRejectedReducers,
    ...fetchResetReducers,
    [getJobApplicationsConstants.fulfilled]: (state, { payload, meta }) => {
      state.fetchOps.getJobApplications.status = 'succeeded';
      state.fetchOps.getJobApplications.error = null;
      state.fetchOps.getJobApplications.meta.pointer = meta.pointer;
      state.posts = state.posts.concat(payload);
    },
    [getJobApplicationsConstants.reset]: (state) => {
      state.fetchOps.getJobApplications.status = 'idle';
      state.fetchOps.getJobApplications.error = null;
    },
  },
});

export const { postsLoaded, postUpdated, postDeleted } = jobApplicationsSlice.actions;

export default jobApplicationsSlice.reducer;
