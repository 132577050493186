import {
  fetchOpsInitStateCreator,
  fetchPendingReducersCreator,
  fetchRejectedReducersCreator,
  fetchResetReducersCreator,
} from '../../utils/helpers/fetchReducerHelpers';
import {
  createJobPostingConstants,
  deleteJobPostingConstants,
  getJobPostingsConstants,
  updateJobPostingConstants,
} from '../constants/jobPostingsConstants';

import { JOB_POSTINGS_DOMAIN_NAME } from '../constants/constants';

/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

const fetchOpNames = {
  pending: [
    'createJobPosting',
    'getJobPostings',
    'updateJobPosting',
  ],
  rejected: [
    'createJobPosting',
    'getJobPostings',
    'updateJobPosting',
  ],
  reset: [
    'createJobPosting',
    'getJobPostings',
    'updateJobPosting',
    'deleteJobPosting',
  ],
};

const fetchActionNames = {
  pending: [
    createJobPostingConstants,
    getJobPostingsConstants,
    updateJobPostingConstants,
  ],
  rejected: [
    createJobPostingConstants,
    getJobPostingsConstants,
    updateJobPostingConstants,
  ],
  reset: [
    createJobPostingConstants,
    getJobPostingsConstants,
    updateJobPostingConstants,
    deleteJobPostingConstants,
  ],
};

const fetchOps = fetchOpsInitStateCreator([
  'createJobPosting',
  'getJobPostings',
  'updateJobPosting',
  'deleteJobPosting',
]);

const fetchPendingReducers = fetchPendingReducersCreator(fetchActionNames.pending, fetchOpNames.pending, 'fetchOps');

const fetchRejectedReducers = fetchRejectedReducersCreator(fetchActionNames.rejected, fetchOpNames.rejected, 'fetchOps');

const fetchResetReducers = fetchResetReducersCreator(fetchActionNames.reset, fetchOpNames.reset, 'fetchOps');

const initialState = {
  posts: [],
  fetchOps,
};

const jobPostingsSlice = createSlice({
  name: JOB_POSTINGS_DOMAIN_NAME,
  initialState,
  extraReducers: {
    ...fetchPendingReducers,
    ...fetchRejectedReducers,
    ...fetchResetReducers,
    [createJobPostingConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.createJobPosting.status = 'succeeded';
      state.fetchOps.createJobPosting.error = null;
      state.posts.unshift(payload);
    },
    [getJobPostingsConstants.fulfilled]: (state, { payload, meta }) => {
      state.fetchOps.getJobPostings.status = 'succeeded';
      state.fetchOps.getJobPostings.error = null;
      state.fetchOps.getJobPostings.meta.pointer = meta.pointer;
      state.posts = state.posts.concat(payload);
    },
    [getJobPostingsConstants.reset]: (state) => {
      state.fetchOps.getJobPostings.status = 'idle';
      state.fetchOps.getJobPostings.error = null;
    },
    [updateJobPostingConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.updateJobPosting.status = 'succeeded';
      state.fetchOps.updateJobPosting.error = null;
      const index = state.posts.findIndex((post) => post.id === payload.id);
      state.posts[index] = { ...state.posts[index], ...payload };
    },
    [deleteJobPostingConstants.fulfilled]: (state, { payload }) => {
      state.fetchOps.deleteJobPosting.status = 'succeeded';
      state.fetchOps.deleteJobPosting.error = null;
      state.fetchOps.deleteJobPosting.meta.id = payload;
      state.posts = state.posts.filter((post) => post.id !== payload);
    },
    [deleteJobPostingConstants.rejected]: (state, action) => {
      state.fetchOps.deleteJobPosting.status = 'failed';
      state.fetchOps.deleteJobPosting.error = action.error?.message || action.error;
      state.fetchOps.deleteJobPosting.meta.id = action.meta.id;
    },
  },
});

export const { postsLoaded, postUpdated, postDeleted } = jobPostingsSlice.actions;

export default jobPostingsSlice.reducer;
