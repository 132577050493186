import { all } from 'redux-saga/effects';
// SAGAS
// assets
import blockPostSagas from './blogPostsSagas';
import jobPostingSagas from './jobPostingsSagas';
import imageSagas from './imagesSagas';
import jobApplicationSagas from './jobApplicationsSagas';
import messagesSagas from './messagesSagas';

export default function* rootSaga() {
  yield all([
    blockPostSagas(),
    jobPostingSagas(),
    imageSagas(),
    jobApplicationSagas(),
    messagesSagas(),
  ]);
}
