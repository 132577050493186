import {
  createBlogPostConstants,
  deleteBlogPostConstants,
  getBlogPostsConstants,
  updateBlogPostConstants,
} from '../constants/blogPostsConstants';

const { createFetchStandartFlowActionCreators } = require('../../utils/helpers/actionCreatorHelpers');

// eslint-disable-next-line import/prefer-default-export
export const createBlogPostActionCreators = createFetchStandartFlowActionCreators(
  createBlogPostConstants,
);

export const getBlogPostsActionCreators = createFetchStandartFlowActionCreators(
  getBlogPostsConstants,
);

export const updateBlogPostActionCreators = createFetchStandartFlowActionCreators(
  updateBlogPostConstants,
);

export const deleteBlogPostActionCreators = createFetchStandartFlowActionCreators(
  deleteBlogPostConstants,
);
