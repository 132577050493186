import { createActionNameForProcessingFetch } from '../../utils/helpers/actionCreatorHelpers';
import { JOB_POSTINGS_DOMAIN_NAME } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const createJobPostingConstants = createActionNameForProcessingFetch('createJobPosting', JOB_POSTINGS_DOMAIN_NAME);

export const getJobPostingsConstants = createActionNameForProcessingFetch('getJobPostings', JOB_POSTINGS_DOMAIN_NAME);

export const updateJobPostingConstants = createActionNameForProcessingFetch('updateJobPosting', JOB_POSTINGS_DOMAIN_NAME);

export const deleteJobPostingConstants = createActionNameForProcessingFetch('deleteJobPosting', JOB_POSTINGS_DOMAIN_NAME);
